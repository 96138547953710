
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResponsiveNavbar from '../sections/ResponsiveNavbar';
import Footer from '../sections/Footer';
import { ContactUs } from '../sections/Email';
import BackToTopButton from '../components/BackToTopButton';

const ContactPage = () => {
    return (
        <>
            <ResponsiveNavbar />
            <div className="hero-contact d-flex align-items-center text-center text-white">
                <div className="container max-width p-5">
                    <div className="text-center mb-5">
                        <h2 className="display-4 fw-bold">Contact Us</h2>
                        <div className='title-underline mb-4'></div>
                        <h2 className="display-6">We would love to hear from you.</h2>
                    </div>
                </div>
            </div>

            <section className="bg-light">
                <div className="container max-width p-5">
                    <div className="text-center mb-5">
                        <p className="text-uppercase text-muted">Don't be shy</p>
                        <h2 className="display-4 fw-bold">Let's Talk</h2>
                        <div className='title-underline mb-4'></div>
                        <ContactUs />

                    </div>
                </div>
            </section>
            <BackToTopButton />
            <Footer />
        </>
    );
};

export default ContactPage;

