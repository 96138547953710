import React from 'react';
import './App.css';
import './Responsive.css';
import { Route, Routes } from "react-router-dom";
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import HowItWorks from './pages/HowItWorks';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/how" element={<HowItWorks />} />
      </Routes>
    </div>
  );
}

export default App;
