import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../components/scrollUtils'

const CoverSection = () => {
  
  const jumbotronStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    marginTop: '0px',
    marginBottom: '0px',
    minHeight: '70vh',
  };

  return (
    <div className="jumbotron d-flex align-items-center p-3" style={jumbotronStyle}>
      <div className="container max-width text-center">
        <div className="row justify-content-center">
          <div className="col-md-8 border border-white p-4">
            <h2 className="display-4 fw-bold text-white">Ready to ignite your new business vision?</h2>
            <div className='title-underline mb-4'></div>
            <p className="text-white">Unlocking the Path to Success: Turning Your Business Dreams into Reality</p>
            <Link to="/contact" className="btn btn-dark btn-block rounded-pill mt-4 ps-5 pe-5" onClick={scrollToTop}>Get Started</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverSection;
