
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    return (
        <footer className="bg-dark py-3">
            <div className="container max-width text-white p-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <img
                                    src="./corescene.com192.png"
                                    alt="CoreScene"
                                    className="img-fluid"
                                    style={{ width: '128px', height: '128px' }}
                                />
                            </div>
                            <div className="col-md-8">
                                <h1 className="h2 mb-0">
                                    CoreScene
                                </h1>
                                <p className="mt-0 d-none">
                                    Fueling Your Business Journey
                                </p>
                                <p className="small mt-3">
                                    Whether you’re a seasoned entrepreneur or just starting, let us accompany you on this exhilarating adventure, where your aspirations and ambitions meet their true potential. Together, we can make your entrepreneurial dreams a reality.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <h2 className="h2">Let’s connect.</h2>
                        <ul className="list-unstyled">
                            <li>
                                <a href="https://www.linkedin.com/company/corescene" className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                    LinkedIn
                                </a>
                            </li>
                        </ul>
                        <h2 className="h2">Links</h2>

                        <ul className="list-unstyled">
                            <li>
                                <a href="/" className="text-decoration-none">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="/contact" className="text-decoration-none">
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                <a href="about" className="text-decoration-none">
                                    About Us
                                </a>
                            </li>
                        </ul>

                        <div className="d-none">
                            <form
                                action="https://subscribe.wordpress.com"
                                method="post"
                                acceptCharset="utf-8"
                                data-blog="223961387"
                                data-post_access_level="everybody"
                                id="subscribe-blog"
                            >
                                <div className="form-group">
                                    <input
                                        required
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Type your email…"
                                        value="eli.zuhairi@gmail.com"
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-secondary btn-block"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center text-secondary'>CoreScene.com | 2023</div>
        </footer>
    );
};

export default Footer;
