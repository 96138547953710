import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const [floating, setFloating] = useState(false); // Add a floating state

  useEffect(() => {
    // Add a scroll event listener to check if the user has scrolled down the page
    window.addEventListener('scroll', handleScroll);
    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Show the button when the user has scrolled 100 pixels down the page
    if (window.scrollY > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }

    // Toggle the floating state based on scroll position
    if (window.scrollY > 200) { // You can adjust this value as needed
      setFloating(true);
    } else {
      setFloating(false);
    }
  };

  const scrollToTop = () => {
    // Scroll to the top of the page when the button is clicked
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {showButton && (
        <button
          className={`back-to-top-btn btn btn-warning ${floating ? 'floating' : ''}`}
          onClick={scrollToTop}
        >
          <i className='fa fa-arrow-up'></i>
        </button>
      )}
    </>
  );
};

export default BackToTopButton;
