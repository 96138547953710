import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../components/scrollUtils'

const QuickStartGuide = () => {

  return (
    <section className="bg-white py-5">
      <div className="container max-width p-5">
        <div className='align-items-center'>
          <h4 className="text-center text-uppercase text-muted font-weight-bold">Quick Start Guide</h4>
          <h2 className="text-center display-4 fw-bold">6-step success: Your design roadmap</h2>
          <div className='title-underline mb-4'></div>
          <p className="text-center pb-5 ps-5 pe-5">
            We've developed a meticulously crafted, comprehensive six-step process that acts as your personal roadmap, guiding you steadily towards your business goals.
          </p>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <div className="step-number mr-3">01.</div>
                <h3 className="font-weight-bold">Discovery & Vision</h3>
                <p>Define your project’s objectives and vision. Understand your target audience, goals, and the message you want to convey through your design.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <div className="step-number mr-3">02.</div>
                <h3 className="font-weight-bold">Research & Inspiration</h3>
                <p>Dive into research to gather insights and inspiration. Explore competitors, industry trends, and design styles to inform your creative direction.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <div className="step-number mr-3">03.</div>
                <h3 className="font-weight-bold">Concept Development</h3>
                <p>Brainstorm and sketch out initial design concepts. Experiment with layouts, color schemes, typography, and imagery to find the perfect visual identity.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <div className="step-number mr-3">04.</div>
                <h3 className="font-weight-bold">Design & Iteration</h3>
                <p>Bring your concepts to life by creating detailed designs. Iterate and refine, seeking feedback and making necessary adjustments to ensure your design aligns with your goals.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <div className="step-number mr-3">05.</div>
                <h3 className="font-weight-bold">Development & Execution</h3>
                <p>Transform your finalized design into a functional product or website. Collaborate with developers to ensure seamless integration of design elements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <div className="step-number mr-3">06.</div>
                <h3 className="font-weight-bold">Testing & Launch</h3>
                <p>Conduct thorough testing to ensure your design functions flawlessly across devices and platforms. Once everything is set, launch your project, making it accessible to your audience.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <Link to="/contact" className="btn btn-dark btn-block rounded-pill mt-0 mb-5 ps-5 pe-5" onClick={scrollToTop}>Start Now</Link>
      </div>
    </section>
  );
};

export default QuickStartGuide;
