
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeroSection from '../sections/HeroSection';
import AboutUs from '../sections/AboutUs';
import QuickStartGuide from '../sections/QuickStartGuide';
import Pricing from '../sections/Pricing';
import CoverSection from '../sections/CoverSection';
import Footer from '../sections/Footer';
import ContactForm from '../sections/ContactForm';
import ResponsiveNavbar from '../sections/ResponsiveNavbar';
import BackToTopButton from '../components/BackToTopButton';
import Services from '../sections/Services';
import PlatformImage from '../sections/PlatformImage';

const HomePage = () => {
    return (
        <>
            <ResponsiveNavbar />
            <HeroSection />
            <PlatformImage />
            <AboutUs />
            <QuickStartGuide />
            <Services />
            <Pricing />
            <CoverSection />
            <ContactForm />
            <Footer />
            <BackToTopButton />
        </>
    );
};

export default HomePage;

