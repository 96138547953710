import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResponsiveNavbar from '../sections/ResponsiveNavbar';
import Footer from '../sections/Footer';
import { Link } from 'react-router-dom';
import BackToTopButton from '../components/BackToTopButton';
import { scrollToTop } from '../components/scrollUtils'

const AboutPage = () => {
  return (
    <>
      <ResponsiveNavbar />
      <div className="hero-about d-flex align-items-center text-center text-white">
        <div className="container max-width p-5">
          <div className="text-center mb-5">
            <h2 className="display-4 fw-bold">About Us</h2>
            <div className='title-underline mb-4'></div>
            <h2 className="display-6">Empowering Your Web Presence with<br />  HubSpot, WordPress & Wix.</h2>
          </div>
        </div>
      </div>

      <section className="bg-light py-5">
        <div className="container max-width p-5">
          <div className="text-center mb-5">
            <h2 className="display-4 fw-bold">Why choose CoreScene?</h2>
            <div className='title-underline mb-4'></div>
            <h2 className="display-6">Empowering Your Web Presence with<br />  HubSpot, WordPress & Wix.</h2>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="mb-4 p-3">
                <div className="card-body">
                  <h3 className="card-title">Innovative Edge</h3>
                  <p className="card-text">CoreScene offers innovative and cutting-edge website solutions, setting your online presence apart.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-4 p-3">
                <div className="card-body">
                  <h3 className="card-title">Speed and Efficiency</h3>
                  <p className="card-text">We provide fast, world-class design for HubSpot, WordPress or Wix websites, ensuring a swift and efficient launch.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-4 p-3">
                <div className="card-body">
                  <h3 className="card-title">Exceptional Expertise</h3>
                  <p className="card-text">With our team of seasoned professionals, you’ll benefit from exceptional web design and development expertise to realize your vision.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-4 p-3">
                <div className="card-body">
                  <h3 className="card-title">Tailored Solutions</h3>
                  <p className="card-text">We specialize in crafting tailored web solutions, ensuring your online presence aligns perfectly with your unique goals and needs.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-4 p-3">
                <div className="card-body">
                  <h3 className="card-title">Ongoing Support</h3>
                  <p className="card-text">Count on CoreScene for ongoing support and maintenance, guaranteeing your website remains at its peak performance.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="location-section bg-light py-5">
        <div className="container max-width p-5">
          <div className="text-center mb-5">
            <h2 className="display-4 fw-bold">Our Location?</h2>
            <div className='title-underline mb-4'></div>
            <h2 className="display-6">We're in <strong className='fw-bold'>Espoo</strong>, a city in Finland. If your location is in Finland, we're more than willing to coordinate a face-to-face meeting in either Espoo or Helsinki.</h2>
            <Link to="/contact" className="btn btn-dark btn-block rounded-pill mt-4 ps-5 pe-5" onClick={scrollToTop}>Don't be a stranger; drop us a line.</Link>
          </div>
        </div>
      </section>
      <BackToTopButton />
      <Footer />
    </>

  );
};

export default AboutPage;
