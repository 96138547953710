import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Platforms from '../img/hero/platforms-half.png';

const PlatformImage = () => {
  return (

    <section className="position-absolute start-50 translate-middle-x bg-light hidden-xxs hidden-xs hidden-sm" style={{ zIndex: '10000000'}}>
      <div>
        <img className="image-size wow slideInUp" data-wow-delay="0.1s" src={Platforms} alt="Platforms" />
      </div>
    </section>
  );
};

export default PlatformImage;
