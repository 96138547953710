import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Services = () => {
  return (
    <section className="brand-bg py-5">
      <div className="container max-width p-5">
        <h2 className='display-4 fw-bold mt-5 text-center'>Budget-friendly Services</h2>
        <div className='title-underline-light mb-4'></div>
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <h3 className="font-weight-bold">Cost Analysis</h3>
                <p>Conduct a thorough cost analysis before starting any project. Understand the client's budget constraints and provide transparent pricing. Offer different packages or pricing tiers to accommodate various budgets.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <h3 className="font-weight-bold">Open Source Platforms</h3>
                <p>Utilize open-source platforms like WordPress whenever possible. These platforms offer cost-effective solutions with a wide range of plugins and themes available for customization.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <h3 className="font-weight-bold">Template Usage</h3>
                <p>In the case of Wix, leverage pre-designed templates to save time and costs. Customize these templates to align with the client's branding and requirements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <h3 className="font-weight-bold">Efficient Development</h3>
                <p>Streamline your development process to reduce man-hours and, consequently, costs. Efficient coding practices and reusable components can help speed up development without compromising quality.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <h3 className="font-weight-bold">Maintenance Plans</h3>
                <p>Offer cost-effective maintenance plans to ensure the website's longevity. Regular updates, security patches, and content updates can be bundled into these plans, providing ongoing value to clients.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4">
              <div className="p-3">
                <h3 className="font-weight-bold">Training and Support</h3>
                <p>Empower clients with the knowledge to make minor updates and edits themselves, reducing the need for constant developer intervention and associated costs.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
