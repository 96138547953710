
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResponsiveNavbar from '../sections/ResponsiveNavbar';
import Footer from '../sections/Footer';
import BackToTopButton from '../components/BackToTopButton';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../components/scrollUtils'

const HowItWorks = () => {
    return (
        <>
            <ResponsiveNavbar />
            <div className="how-section d-flex align-items-center text-center text-white">
                <div className="container max-width p-5">
                    <div className="text-center mb-5">
                        <h2 className="display-4 fw-bold">How it works?</h2>
                        <div className='title-underline mb-4'></div>
                        <h2 className="display-6">What do you pay for and what are the returns on your investments?</h2>
                    </div>
                </div>
            </div>

            <section className="bg-light py-5">
                <div className="container max-width p-5">
                    <div className="p-3">
                        <p className="text-center text-uppercase text-muted">Les's start with the obvious</p>
                        <h2 className="text-center display-4 fw-bold">What do you pay for?</h2>
                        <div className='title-underline mb-4'></div>
                        <p>
                            When it comes to designing your website using HubSpot, Wix, or WordPress, you'll need to budget for the website design service itself. In addition to this, there are some other costs to keep in mind. For instance, you'll need to cover the expenses for your domain name. If you opt for a custom domain with WordPress, there might be a premium cost involved. Similarly, with Wix, choosing premium features can give your site a more professional look. The same principle applies to HubSpot.
                        </p>
                        <p>
                            However, if you're looking to save money, you also have the option of setting up your own server to install WordPress. In summary, along with our design service, you should be prepared for these additional expenses that are part of the website development process.
                        </p>
                    </div>
                    <div className='text-center mb-2'>
                        <Link to="/contact" className="btn btn-dark btn-block rounded-pill ps-5 pe-5" onClick={scrollToTop}>Contact Us</Link>
                    </div>
                </div>

            </section>

            <section className="bg-light">
                <div className="container max-width p-5">
                    <div className="mb-2 p-3">
                        <p className="text-center text-uppercase text-muted">WE'VE GOT YOU COVERED!</p>
                        <h2 className="text-center display-4 fw-bold">Explore our website maintenance and credit options</h2>
                        <div className='title-underline mb-4'></div>
                        <p>
                            If you're interested in outsourcing the maintenance of your website, we can provide that service as well. The extent of the services and the frequency at which you require them can be credited based on your previous purchases. If you've previously acquired website services from us, you'll accumulate credits that can be used for future website purchases or maintenance needs.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg-light">
                <div className="container max-width p-5">
                    <div className="mb-2 p-3">
                        <p className="text-center text-uppercase text-muted">The Power of Design!</p>
                        <h2 className="text-center display-4 fw-bold">Enhancesing Customer Engagement</h2>
                        <div className='title-underline mb-4'></div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <p>
                                    <strong> Attracting Customers:</strong> Good design can help bring in more customers, keeping them engaged and possibly boosting your income and customer satisfaction.
                                </p>
                            </div>
                            <div className='col-md-4'>
                                <p>
                                    <strong>Competing Better:</strong> Effective design sets you apart from rivals, making your product or service more appealing to customers.
                                </p>
                            </div>
                            <div className='col-md-4'>
                                <p>
                                    <strong>Saving Costs:</strong> Proper design and development can make your operations more efficient, cutting long-term expenses.
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <p>
                                    <strong>Building Your Brand:</strong> A strong visual style helps more people recognize your brand and stick with it.
                                </p>
                            </div>
                            <div className='col-md-4'>
                                <p>
                                    <strong>Boosting Income:</strong> If you make money from your product, user-friendly design can increase sales and your overall revenue.
                                </p>
                            </div>
                            <div className='col-md-4'>
                                <p>
                                    <strong> Happy Customers:</strong> Well-thought-out design often means satisfied customers who stay loyal to your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <BackToTopButton />
            <Footer />
        </>
    );
};

export default HowItWorks;

