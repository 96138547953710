import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import { scrollToTop } from '../components/scrollUtils'

class HeroSection extends React.Component {
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();
  }

  render() {
    return (
      <div className="hero-section d-flex align-items-center text-center text-white">
        <div className="container max-width">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h1 className="display-4 fw-bold font-weight-bold mb-4 d-none">A professional approach to grow your business.</h1>
              <h1 className="display-4 fw-bold font-weight-bold mb-4 wow slideInUp" data-wow-delay="0.1s">Don't miss out on potential leads because of a poorly designed website.</h1>
              <div className="title-underline mb-4 wow zoomIn" data-wow-delay="0.2s"></div>
              <h2 className="lead wow slideInUp" data-wow-delay="0.2s">
                Choose your platform <strong>—HubSpot, Wix, WordPress—</strong> and we're here to help.
              </h2>
              <Link to="/contact" className="btn btn-dark btn-block rounded-pill mt-4 ps-5 pe-5 wow slideInUp" onClick={scrollToTop} data-wow-delay="0.2s">Get Started</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroSection;
