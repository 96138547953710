import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../components/scrollUtils'

const Pricing = () => {

  return (
    <section className="pricing">
      <div className="container max-width p-5">
        <div className="text-center mb-5">
          <p className="text-uppercase text-muted">Simple pricing</p>
          <h2 className="display-4 fw-bold">Minimal investment, <br />Maximum results</h2>
          <div className='title-underline mb-4'></div>
          <p>Enjoy high-quality experiences at incredibly competitive prices.</p>
        </div>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card p-3">
              <div className="card-body">
                <h3 className="card-title">Starter Plan</h3>
                <p className="card-text">One-time Payment</p>
                <h4 className="card-price">€400</h4>
                <Link to="/contact" className="btn btn-dark btn-block rounded-pill mt-4 ps-5 pe-5" onClick={scrollToTop}>Contact Us</Link>
              </div>
            </div>
            <ul className="list-unstyled mt-3 pe-4 ps-4 pb-4">
              <li><i className='fa fa-plus-circle me-2'></i>HubSpot, Wix, and WordPress</li>
              <li><i className='fa fa-plus-circle me-2'></i>Single Page Design</li>
              <li><i className='fa fa-plus-circle me-2'></i>Single Language</li>
              <li><i className='fa fa-plus-circle me-2'></i>Two Revisions</li>
              <li><i className='fa fa-plus-circle me-2'></i>10 Euros / Professianal Image</li>
            </ul>

          </div>

          <div className="col-md-4 mb-4">
            <div className="card p-3">
              <div className="card-body">
                <h3 className="card-title">Performance Plan</h3>
                <p className="card-text">One-time Payment</p>
                <h4 className="card-price">€900</h4>
                <Link to="/contact" className="btn btn-dark btn-block rounded-pill mt-4 ps-5 pe-5" onClick={scrollToTop}>Contact Us</Link>
              </div>
            </div>
            <ul className="list-unstyled mt-3 pe-4 ps-4 pb-4">
              <li><i className='fa fa-plus-circle me-2'></i>Starter Plan</li>
              <li><i className='fa fa-plus-circle me-2'></i>Up to 3 Pages</li>
              <li><i className='fa fa-plus-circle me-2'></i>Up to 2 Langs Per Page</li>
              <li><i className='fa fa-plus-circle me-2'></i>Up to 3 Revisions Per Page</li>
              <li><i className='fa fa-plus-circle me-2'></i>Custom Domain Setup</li>
              <li><i className='fa fa-plus-circle me-2'></i>Business E-mail Setup</li>
            </ul>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card p-3">
              <div className="card-body">
                <h3 className="card-title">Elite Plan</h3>
                <p className="card-text">One-time or Subscription</p>
                <h4 className="card-price">Let's Talk</h4>
                <Link to="/contact" className="btn btn-dark btn-block rounded-pill mt-4 ps-5 pe-5" onClick={scrollToTop}>Contact Us</Link>
              </div>
            </div>
            <ul className="list-unstyled mt-3 pe-4 ps-4 pb-4">
              <li><i className='fa fa-plus-circle me-2'></i>Product and Service Design</li>
              <li><i className='fa fa-plus-circle me-2'></i>Scope of Work</li>
              <li><i className='fa fa-plus-circle me-2'></i>Tech Stack (ReactJS & Flutter)</li>
              <li><i className='fa fa-plus-circle me-2'></i>Timeline</li>
              <li><i className='fa fa-plus-circle me-2'></i>Understandign Your Budget</li>
              <li><i className='fa fa-plus-circle me-2'></i>Streamline and Plan</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="text-center mt-5">
          <p className="text-uppercase text-muted">We've got you covered!</p>
          <h3 className="card-title">Do You Need Website Maintenance Assistance?</h3>
          <Link to="/how" className="btn btn-dark btn-block rounded-pill mt-4 ps-5 pe-5" onClick={scrollToTop}>How It Works</Link>
        </div>

      </div>
    </section>
  );
};

export default Pricing;
