import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <section className="bg-light py-5">
      <div className="hidden-xxs hidden-xs hidden-sm" style={{ marginTop: '120px' }} />
      <div className="container max-width p-5">
        <div className="text-center mb-5">
          <p className="text-uppercase text-muted">About Us</p>
          <h2 className="display-4 fw-bold">Why choose CoreScene?</h2>
          <div className='title-underline mb-4'></div>
          <h2 className="display-6">Empowering Your Web Presence with<br /> <strong> HubSpot, WordPress & Wix.</strong></h2>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="mb-4 p-3">
              <div className="card-body">
                <h3 className="card-title">Innovative Edge</h3>
                <p className="card-text">CoreScene offers innovative and cutting-edge website solutions, setting your online presence apart.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-4 p-3">
              <div className="card-body">
                <h3 className="card-title">Speed and Efficiency</h3>
                <p className="card-text">We provide fast, world-class design for HubSpot, WordPress or Wix websites, ensuring a swift and efficient launch.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-4 p-3">
              <div className="card-body">
                <h3 className="card-title">Exceptional Expertise</h3>
                <p className="card-text">With our team of seasoned professionals, you’ll benefit from exceptional web design and development expertise to realize your vision.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
