
import { ContactUs } from './Email';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactForm = () => {
    return (
        <section className="bg-light">
            <div className="container max-width p-5">
                <div className="text-center mb-5">
                    <p className="text-uppercase text-muted">Don't be shy</p>
                    <h2 className="display-4 fw-bold">Let's Talk</h2>
                    <div className='title-underline mb-4'></div>
                    <ContactUs />
                </div>
            </div>
        </section>
    );
};

export default ContactForm;

